import LOCALES from './locales';
import get_states from './states';

export default {
  account: {
    customer_account: 'Compte client',
    customer: 'Client',
    email: 'E-mail',
    phone_number: 'Téléphone',
    address: 'Adresse',
    shipping_address: 'Adresse de livraison',
    firstname: 'Prénom',
    lastname: 'Nom',
  },
  dashboard: {
    merchants: 'Comptes',
    search_globally: 'Rechercher par référence ou par mot-clé (ex. lastname:dupont)',
    no_merchant_intro: "Vous n'avez pas configuré de solution de paiement pour l'instant",
    merchant_contact_us_help:
      "Si vous n’avez pas le temps de créer une solution de paiement, <a href='https://pledg.co/contact-us/' class='font-bold' target='_blank'>laissez-nous vos coordonnées</a> et on le fera pour vous.",
    merchant_list_empty: 'Aucune solution de paiement',
    tabs: {
      merchant_purchases: 'Achats',
      merchant_transfers: 'Virements',
      merchant_exports: 'Fichiers exportés',
      merchant_settings: 'Paramètres',
      merchant_collaborators: 'Collaborateurs',
      merchant_orders: 'Commandes',
    },
    merchant_select_multiple: 'Sélection multiple',
    merchant_show_disabled: 'Afficher désactivés',
    disabled: 'désactivé',
    show_display_options: 'Afficher options',
    hide_display_options: 'Cacher options',
    select_all: 'Tout sélectionner ({n})',
    unselect_all: 'Tout désélectionner ({n})',
  },
  errors: {
    try_again: "Essayez à nouveau dans un instant s'il-vous-plaît.",
    unknown: 'Une erreur inconnue est survenue.',
    check_form_data: 'Veuillez vérifier les données de votre formulaire',
    cannot_check_purchase_validity: "Impossible de vérifier la validité de l'achat",
    some_fields_are_mandatory: 'Champs obligatoires',
    please_fill_fields: 'Merci de renseigner les champs obligatoires',
    field_is_empty: 'Ce champ est vide',
    resource_not_allowed:
      "Vous n'avez pas les autorisations nécessaires pour accéder à la ressource demandée.",
    resource_not_found: "La ressource demandée n'a pas pu être trouvée",
    updating_own_profile_not_allowed: "Vous n'êtes pas autorisé à modifier votre propre profil.",
    updating_unrelated_user_not_allowed: "Vous n'êtes pas autorisé à modifier ce collaborateur.",
    b2b_company_not_found: 'Société non référencée en base.',
  },
  export: {
    column: {
      uid: 'Référence',
      merchants: 'Solutions de paiement',
      state: 'Statut',
      type: 'Type',
      created: 'Créé le',
    },
    back_to_list: 'Retour à la liste',
    list_empty_items: "Aucun fichier d'export trouvé",
    list_empty_items_infos:
      "Vous retrouverez ici vos données exportées depuis l'onglet 'Achats' ou 'Virements'",
    list_page_title: 'Liste des fichiers exportés',
    list_page_info:
      'Pour exporter un nouveau fichier, sélectionnez les marchands dans le panneau de gauche en cliquant dessus',
    post_page_title: 'Exporter des données',
    post_page_info: 'Sélectionner les marchands dans le panneau de gauche en cliquant dessus',
    post_success_title: "L'export est en cours",
    post_success_text: "Retrouvez les fichiers générés dans l'onglet 'Fichiers exportés'",
  },
  filters: {
    acceptance_state: "Statut d'acceptation",
    acceptance_rejection_type: 'Type de rejet',
    after: 'Après',
    before: 'Avant',
    between: 'Entre',
    amount: 'Montant',
    apply: 'Appliquer',
    clear: 'Effacer',
    date: 'Date',
    equal: 'Égal',
    less: 'Inférieur ou égal à',
    filters: 'Filtres',
    more: 'Supérieur ou égal à',
    state: 'Statut',
    errors: 'Erreurs',
    is_purchase_link: "Est-ce un lien d'achat ?",
    yes: 'Oui',
    no: 'Non',
  },
  general: {
    activate: 'Activer',
    cancel: 'Annuler',
    delete: 'Supprimer',
    copy: 'Copier',
    warning: 'Attention',
    filter: 'Filtrer',
    next_page: 'Suivant',
    previous_page: 'Précédent',
    search: 'Recherche',
    export: 'Exporter',
    close: 'Fermer',
    edit: 'Modifier',
    validate: 'Valider',
    refresh: 'Rafraîchir',
    update_available: 'Une mise à jour est disponible!',
    payment_solution: 'Solution de paiement',
    payment_solutions: 'Solutions de paiement',
    company: 'Entreprise',
    companies: 'Entreprises',
    copy_successful: 'Le texte a été copié avec succès',
    copy_failed: "Le texte n'a pas pu être copié",
    limit_reached: 'Limite atteinte',
    max_merchants_selected:
      'Pour des raisons techniques, le nombre maximum de solutions de paiement sélectionnables est de 100',
    not_found_route: "La page que vous avez demandée n'a pas été trouvée",
    back_to_app: "Retourner à l'application",
    submit: 'Valider',
  },
  documents: {
    title: 'Vos documents',
    list_empty_items: 'Pas de documents de facturation',
    list_empty_items_infos: 'Vous trouverez vos factures et avoirs quand ceux-ci seront émis.',
    billing: {
      info: 'Factures & Avoirs',
      type: 'Type',
      reference: 'Référence',
      date: 'Date',
      download: 'Téléchargement',
      invoice: 'Facture',
      credit_note: 'Avoir',
      amount: 'Montant',
      state: 'État',
      due_date: 'Échéance',
    },
    tabs: {
      billing: 'Facturation',
    },
  },
  key_indicators: {
    title: 'Vos indicateurs de performance',
    tabs: {
      gmv: 'GMV',
      acceptance: 'Acceptation',
      risk: 'Risque',
      billing: 'Facturation',
    },
  },
  user_settings: {
    title: 'Paramètres',
    tabs: {
      collaborators: "Collaborateurs de l'entreprise",
      companies: 'Entreprises',
    },
    collaborators: {
      info: 'Les utilisateurs suivants font partie de votre entreprise',
      email: 'Email',
      profile: 'Profil',
      disabled: 'Désactivé',
      update_collaborator_success: 'La modification a bien été prise en compte',
    },
    companies: {
      info: 'Sélectionnez une entreprise rattachée à votre compte',
      empty_list: 'Aucune entreprise trouvée',
    },
  },
  landing: {
    email: 'Email',
    forgot: 'Mot de passe oublié?',
    home: 'Bienvenue',
    home_subtitle: 'Connectez-vous à votre dashboard',
    login: 'Connexion',
    login_subtitle: 'Bienvenue ! Entrez vos identifiants pour vous connecter à votre dashboard.',
    otp_verification: 'Vérification OTP',
    otp_verification_subtitle: 'Veuillez entrer le code reçu par email pour vous connecter.',
    password: 'Mot de passe',
    otp_code: 'Entrez le code',
    otp_code_invalid: 'Le code OTP est invalide',
    otp_invalid_user_state: 'Veuillez vous reconnecter avec votre email et mot de passe',
    otp_back_to_login: 'Retour à la page de connexion',
    login_invalid_credentials: 'Vos informations de connexion semblent être invalides.',
  },
  menu: {
    exports: 'Exports',
    home: 'Tableau de bord',
    purchase_link: "Lien d'achat",
    new_account: 'Comptes',
    signout: 'Quitter',
    key_indicators: 'Indicateurs clés',
    documents: 'Documents',
    user_settings: 'Paramètres',
  },
  merchant_form: {
    additional_information: 'Informations additionnelles',
    country_code: 'Pays de destination des SMS',
    css_url: 'URL du CSS',
    day: 'Aucun | 1 jour | {n} jours',
    deferred_type: 'Type de paiement différé',
    deferred_delay_in_days: 'Nombre de jours de différé',
    deferred_day_of_next_month: 'Différé au jour du mois prochain',
    installments_fees: '(frais fixe de 0.5%)',
    installments_nb: "Nombre d'échéances",
    installment: 'Aucune | 1 échéance | {n} échéances',
    down_payment: '{percent} %',
    down_payment_percentage: "Pourcentage de l'acompte",
    down_payment_percentage_placeholder: 'Entre 0 et 100',
    down_payment_balance_day: '1 jour | {n} jours',
    down_payment_balance_max_days: 'Nombre max de jours avant capture du solde',
    down_payment_balance_max_days_placeholder: 'Entre 1 et 270',
    language: 'Langue',
    main_information: 'Informations principales',
    payment_information: 'Informations de paiement',
    name: 'Nom de l’entreprise',
    uid: 'UID',
    payment_method_params: {
      iban: 'IBAN',
      bic: 'BIC',
    },
    info: 'Brève description',
    picture_url: 'URL du logo',
    picture_url_empty: 'Aucun logo renseigné',
    picture_url_dropzone: 'Sélectionnez ou déposez un fichier à uploader',
    picture_url_invalid: "Cette image n'est pas valide. Merci de réessayer avec une autre image.",
    payment_type: 'Type de paiement',
    requested_opt: "Consentement de l'acheteur à partager ses données avec Pledg",
    requested_opt__disabled: 'Désactivé',
    requested_opt__in: 'Opt-in (explicit)',
    requested_opt__out: 'Opt-out (implicit)',
    site_url: 'URL du site internet',
    secret: 'Clé secrète',
    reveal_secret: 'Cliquer pour révéler la clé secrète',
    submit_update: 'Modifier',
    timezone: 'Fuseau horaire',
    update_successful: 'Les paramètres ont été mis à jour avec succès',
    upload_picture: 'Cliquez ici pour charger un nouveau logo',
  },
  company_form: {
    main_information: 'Informations principales',
    technical_information: 'Informations techniques',
    name: "Nom de l'entreprise",
    uid: 'UID',
    secret: 'Secret',
    secret_draft: 'Nouveau secret',
    generate_secret_draft: 'Générer un nouveau secret',
    generate_secret_draft_explanation:
      'Générer un nouveau secret en cliquant sur le bouton ci-dessus. Celui-ci sera activé après validation, et remplacera le secret actuel.',
    activate_secret_information:
      "L'activation du nouveau secret supprimera définitement le secret actuel. Êtes-vous sûr de vouloir continuer ?",
    secret_activation_successful: 'Le nouveau secret a été activé avec succès',
  },
  payment_types: {
    DEFERRED: 'Différé',
    ROLLING_DEFERRED: 'Différé (pré-autorisation roulante)',
    INSTALLMENT: 'Fractionné',
    SPLIT: 'Partagé',
    REDIRECTION: 'Redirection',
    DOWN_PAYMENT: 'Avec acompte/solde',
  },
  purchases: {
    details: 'Détail du paiement',
    general_details: 'Informations générales',
    empty: 'Aucun achat à afficher',
    fees: 'Frais',
    purchases: 'Paiements',
    details_uid: 'UID',
    order_uid: 'UID de la commande',
    customer_dashboard_url: 'Dashboard client',
    in_stock: 'En stock',
    out_of_stock: 'En rupture de stock',
  },
  quicksearch: {
    suggestion_label: 'Filtres suggérés',
    results_label: 'Résultats',
    filters: {
      purchase: "UID de l'achat",
      order: 'UID de la commande',
      transfer: 'UID du débit/virement',
      email: 'email du client',
      ref: "référence de l'achat",
      phone: 'téléphone du client',
      firstname: 'prénom du client',
      lastname: 'nom du client',
      statement: 'statement descriptor',
      title: "titre de l'achat",
    },
  },
  shares: {
    installments: 'Échéances',
    split: 'Participants',
    expecting_transfer: 'En attente de virement',
    ancv_share: 'Chèque-Vacances',
  },
  refunds: {
    amount: 'Montant',
    status: 'Statut',
    refunds: 'Remboursements',
    refund: 'Rembourser',
    date: 'Date',
    close_refund_form: 'Fermer',
    cancel_refund_form: 'Annuler',
    request_a_refund: 'Demander un remboursement',
    error_refund_request: "Une erreur s'est produite pendant la demande de remboursement.",
    request_is_sent: 'La demande a été envoyée, elle sera traitée au plus vite.',
    request_a_refund_details: 'Entrez un montant (max: {amount} {currency})',
    requested_refunds: 'Demandes de remboursements',
    no_refund_for_purchase_yet: "Il n'y a pas encore de remboursement pour ce paiement",
    no_refund_request: 'Aucune demande de remboursement initiée',
    partial_refund: '{amount} ont été remboursés',
    partial_not_charge: 'Seulement {amount} sera capturé',
    full_refund: 'Ce paiement a été entièrement remboursé de {amount}',
    full_not_charge: 'Cette part a été entièrement déduite du restant à payer.',
    debited: '{amount} ont été débités',
    not_charged: '{amount} ont été déduits',
    reference: 'Référence',
    initialized: 'Initialisé',
    refused_refund: 'Demande refusée : le montant à rembourser est supérieur au maximum autorisé.',
    duplicate_refund: 'Veuillez patienter une minute et réessayer',
  },
  purchases_table: {
    address: 'Adresse',
    amount: 'Montant',
    availability: 'Disponibilité',
    created: 'Date',
    email: 'Email',
    customer: 'Client',
    name: 'Nom',
    phone_number: 'Téléphone',
    reference: 'Référence',
    state: 'Statut',
    view_detail: 'Voir le détail',
    virtual_card: 'Carte virtuelle',
    transfer: 'Virement',
    debit: 'Débit',
    error_message: 'Erreur',
    refund_operations: 'Remboursements',
    deferred_payment_date: 'Date de paiement différé',
    balance_payment_date: "Date de paiement de l'acompte",
    no_account: 'Pas de compte',
    error: 'Erreur',
    purchase_link: "Lien d'achat",
    essentiel_contract_id: 'Contrat Essentiel',
    open_stripe_documentation: 'Accéder à la documentation Stripe',
    validation_error: 'Erreur de validation',
    payment_error: 'Erreur lors du paiement',
    anonymized_account: 'Compte anonymisé',
    anonymized_data_info: 'Données anonymisées conformément à la réglementation',
  },
  validations: {
    email: 'Le champ doit être une adresse mail valide.',
    required: 'Le champ est obligatoire.',
    min: 'Le champ doit contenir au moins {_param_1_} caractères.',
    max: 'Le champ doit contenir au plus {_param_1_} caractères.',
    min_value: 'Le champ doit être supérieur à {_param_1_}',
    alpha_dash_space:
      'Le champ doit être composé seulement de caractères alpha-numériques ou de tirets.',
    limited_ascii:
      "Le champ doit être composé seulement de caractères alpha-numériques, tiret, espace, point, point-virgule, _ ou {'@'}.",
    url: "L'URL doit être valide (http(s)://site.com/...).",
    percentage: 'Doit être un pourcentage valide (nombre entier entre 0 et 100).',
    delay: 'Doit être un nombre valide de jours (nombre entier entre 1 et 270).',
    css: 'Le champ doit contenir un fichier css valide',
    no_spaces: 'Le champ ne doit contenir aucun espace',
    ean13: 'Le code renseigné ne respecte pas la norme EAN-13',
    numeric: 'Le champ doit être composé seulement de caractères numériques.',
  },
  collaborators: {
    add_collaborator: 'Ajouter un nouveau collaborateur',
    n_collaborators: 'Aucun collaborateur | {n} collaborateur | {n} collaborateurs',
    name: 'Collaborateurs',
    delete_collaborator: 'Supprimer le collaborateur',
    error_add_default: 'Impossible de créer un nouveau collaborateur',
    error_delete_default: 'Impossible de supprimer ce collaborateur',
    error_at_least_one_collaborator: 'Le compte doit avoir au moins un collaborateur',
    error_collaborator_exists: 'Ce collaborateur existe déjà',
    error_merchant_role_not_found: "Ce compte n'a aucun rôle associé",
    error_self_remove_not_allowed: 'Impossible de supprimer votre propre utilisateur',
    error_user_not_found: "Cet utilisateur n'existe pas",
    error_user_role_exists: 'Cet utilisateur est déjà lié à un rôle',
  },
  transfers_table: {
    amount: 'Montant',
    state: 'Statut',
    date: 'Date',
    reference: 'Référence',
    purchases: 'Paiements associés',
    search: 'Filtrer par référence ou par mot-clé (ex. statement:TR123456)',
  },
  transfers: {
    transfer_details: 'Détails du virement',
    debit_details: 'Détails du débit',
    empty: 'Aucun virement à afficher',
    transfers: 'Virements',
  },
  purchase_links: {
    select_merchant: 'Veuillez sélectionner une solution de paiement dans la liste',
    disabled_merchant:
      "Vous ne pouvez pas générer de liens d'achat sur une configuration désactivée",
    enter_a_valid_amount: "Veuillez saisir un montant valide pour simuler l'échéancier de paiement",
    date: 'Date',
    create_link: "Créer un lien d'achat",
    purchase_parameters: "Paramètres de l'achat",
    amount: 'Montant',
    total_amount: 'Montant total',
    including_fees: 'Dont frais',
    currency: 'Monnaie',
    title: 'Titre',
    subtitle: 'Sous titre',
    notification_url: 'Email ou URL de notification de paiement',
    reference: 'Référence',
    buyer_informations: "Informations de l'acheteur",
    b2b_company_name: 'Raison sociale',
    b2b_company_national_id: 'SIREN',
    b2b_insurable_info_section: 'Information assureur',
    b2b_max_outstanding_info:
      'Pour la solution de paiement sélectionnée, montant maximum autorisé :',
    b2b_max_outstanding_cross_purchases_info: "et en plusieurs achats, jusqu'à :",
    b2b_max_outstanding_waiting_for_siren:
      'Veuillez saisir le SIREN pour afficher le montant maximum autorisé pour la solution de paiement sélectionnée.',
    civility: 'Civilité',
    mister: 'M.',
    miss: 'Mme',
    firstname: 'Prénom',
    lastname: 'Nom',
    birthlastname: 'Nom de naissance',
    nationality: 'Nationalité',
    email: 'E-mail',
    phone_number: 'Téléphone',
    use_area_code: "N'oubliez pas de mentionner l'indicatif téléphonique en cas de besoin",
    address: 'Adresse',
    street_number_name: 'Numéro et nom de rue',
    zip_code: 'Code postal',
    city: 'Ville',
    country: 'Pays',
    options: 'Options',
    options_user_interface: 'Interface utilisateur',
    options_schedule: 'Échéancier',
    options_merchant: 'Marchand',
    options_set_second_installment_date: 'Définir une date de second prélèvement',
    secondInstallmentPaymentDate: 'Date de second prélèvement',
    options_set_notification_url: 'Définir un email/URL de notification',
    redirect_url: 'Url de redirection en cas de succès',
    cancel_url: "Url de redirection en cas d'erreur",
    showCloseButton: 'Afficher un bouton fermer',
    language: 'Langue',
    cta_generate_purchase_link: "Générer un lien d'achat",
    here_is_your_link: "Voici le lien d'achat à partager: ",
    link_ready: 'Votre lien est prêt!',
    link_has_been_sent_by_email: 'Le lien suivant a été envoyé par mail',
    link_has_been_sent_by_sms: 'Le lien suivant a été envoyé par sms',
    link_has_been_sent: 'Le lien suivant a été envoyé par mail et sms',
    option_send_by_email: 'Envoyer le lien par mail',
    option_send_by_sms: 'Envoyer le lien par SMS',
    cta_generate_and_send_purchase_link: 'Envoyer le lien généré par mail et par sms',
    cta_generate_and_send_email_purchase_link: 'Envoyer le lien généré par mail',
    cta_generate_and_send_sms_purchase_link: 'Envoyer le lien généré par sms',
    invalid_purchase: 'Achat invalide',
    valid_purchase: 'Achat valide',
    check_this_purchase: 'Vérifier cet achat',
    birth_place: 'Lieu de naissance',
    birth_date: 'Date de naissance',
    birth_city: 'Ville de naissance',
    birth_zipcode: 'Code postal de naissance',
    birth_country: 'Pays de naissance',
    search_city_placeholder: 'Chercher un lieu de naissance',
    search_address_placeholder: 'Chercher une adresse',
    close_modal: 'Fermer',
    simulate_payment_schedule: 'Échéancier de paiement',
    fill_form: 'Veuillez remplir tous les champs obligatoires du formulaire',
    date_from_options: 'La date a été modifiée dans les options',
    product_ean13_reference: 'Référence produit (EAN 13)',
    designation: 'Désignation',
    cart_information: 'Informations du panier',
    enable_merchant_payment_notification_url: 'Recevoir une notification',
    loan_details: 'Caractéristiques de l’offre',
    purchase_amount: 'Montant d’achat',
    loan_duration: 'Nombre de mensualités',
    months: ' mois',
    first_installments_amount: '{installments_nb} mensualités de',
    last_installment_amount: '1 dernière ajustée de',
    delay_in_months: 'Début du crédit dans',
    no_schedule_available:
      'Pas de financement disponible pour cette facilité de paiement avec les paramètres utilisés.',
    taeg: 'TAEG Fixe',
  },
  languages: {
    fr_FR: 'Français',
    en_GB: 'Anglais',
    de_DE: 'Allemand',
    nl_NL: 'Néerlandais',
    it_IT: 'Italien',
    es_ES: 'Espagnol',
    pt_PT: 'Portugais',
  },
  currencies: {
    poundsterling: 'Livre sterling',
    euro: 'Euro',
  },
  profiles: {
    READER: 'Lecteur',
    SELLER: 'Vendeur',
    MANAGER: 'Manager',
    ACCOUNTANT: 'Comptable',
    ADMIN: 'Admin',
  },
  countries: {
    luxembourg: 'Luxembourg',
    germany: 'Allemagne',
    spain: 'Espagne',
    france: 'France',
    netherlands: 'Pays-bas',
    unitedkingdom: 'Royaume-Uni',
    austria: 'Autriche',
    italy: 'Italie',
    belgium: 'Belgique',
    switzerland: 'Suisse',
    portugal: 'Portugal',
  },
  orders: {
    details: 'Détail de la commande',
    informations: 'Informations',
    empty: 'Aucune commande à afficher',
    fees: 'Frais',
    orders: 'Commandes',
    details_uid: 'UID',
    table: {
      address: 'Adresse',
      amount: 'Montant',
      created: 'Date',
      customer: 'Client',
      email: 'Email',
      name: 'Nom',
      phone_number: 'Téléphone',
      reference: 'Référence',
      state: 'Statut',
      view_detail: 'Voir le détail',
      error_message: 'Erreur',
      no_account: 'Pas de compte',
      error: 'Erreur',
      open_stripe_documentation: 'Accéder à la documentation Stripe',
      validation_error: 'Erreur de validation',
      payment_error: 'Erreur lors du paiement',
    },
  },
  misc_order: {
    build_pdf: 'Créer un bon divers',
    build_success: 'Le bon divers a été créé avec succès',
    build_error: 'Une erreur est survenue dans la création du bon divers: {error}',
    label: 'Bon divers',
    pdf_name: 'Bon divers - {date} - {firstname} {lastname} - {reference}.pdf',
    purchase_information: 'Informations concernant votre achat',
    amount_financed: 'Montant financé',
    datetime: 'Date et Heure',
    articles: 'Références produit',
    purchase_summary: 'Achat de {amountCurrency} le {date}',
    reserved_frame: 'Cadre réservé au vendeur',
  },
  product_information: {
    deferred: {
      delay_in_days: 'Report {days} jour(s)',
      day_of_next_month: '{days} du mois suivant',
    },
    installment: '{nb}x',
  },
  states: {
    unknown: 'Non défini',
    ...get_states(LOCALES.FR),
  },
  acceptance_states: {
    unknown: 'Unknown',
    purchase: {
      ACCEPTED: 'Accepté',
      REJECTED: 'Rejeté',
      ABANDONED: 'Abandonné',
      NOT_INITIATED: 'Non initié',
      INITIATED: 'Initié',
      PENDING: 'En attente',
    },
  },
  acceptance_rejection_types: {
    unknown: 'Unknown',
    purchase: {
      TECHNICAL_ERROR: 'Erreur technique',
      PLEDG_REFUSAL: 'Refus Pledg',
      NOT_ELIGIBLE: 'Non éligible',
      CUSTOMER_BANK_REFUSAL: 'Refus banque client',
      MERCHANT_REFUSAL: 'Refus marchand',
    },
  },
  locales: {
    paris_time: 'heure de Paris',
  },
  essentiel: {
    funding: 'Financement',
    trigger_funding: 'Déclencher le financement',
    funding_available: "Le financement n'a pas encore été déclenché pour ce paiement",
    funding_initiated: 'Le financement de ce paiement a déjà été initié',
    funding_failed: 'Le financement de ce paiement a échoué',
    funding_success: 'Le financement de ce paiement a été effectué avec succès',
    release: 'Annulation',
    trigger_release: "Déclencher l'annulation",
    release_available: "L'annulation de ce paiement n'a pas été déclenchée",
    release_initiated: "L'annulation de ce paiement a déjà été effectuée",
    release_available_contact:
      "Pour toute demande d'annulation, merci de contacter {'gestionpartenaires-psp@ca-cf.fr'}",
    release_success: "L'annulation de ce paiement a été effectuée avec succès",
    release_failed: "L'annulation de ce paiement a échoué",
    release_confirmation: 'Voulez-vous vraiment annuler ce paiement ?',
    release_confirmation_button: 'Confirmer',
  },
};
